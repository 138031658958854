<template>
  <div v-if="customField" :class="`q-mt-sm custom-field--${customField.name} ${customField.class}`">
    <label v-if="showLabel" class="text-grey-9 text-bold">{{ customField.label }}</label>

    <!-- Uploaded files -->
    <q-list separator dense class="q-mt-sm q-list--no-padding">
      <q-item clickable v-ripple v-for="file in value" :key="file.url">
        <div>
          <q-item-label class="full-width ellipsis text-blue text-subtitle2 text-bold">
            <a :href="file.url" target="_blank" class="text-blue">
              {{ file.filename }}
              <span class="text-grey">
                {{ formatBytes(file.size) }}
              </span>
            </a>
          </q-item-label>
          <template v-if="isImage(file.mimetype)">
            <a :href="file.url" target="_blank" class="q-mt-sm w-full">
              <q-img class="q-img__attachments" :src="file.url" :href="file.url" target="_blank" />
            </a>
          </template>
        </div>
      </q-item>
    </q-list>
    <div v-if="!value?.length">-</div>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { formatBytes, isImage } from '@/utils/helpers'
import CustomFieldViewMixin from '../CustomFieldViewMixin.vue'

@Options({
  components: {},
  directives: { maska },
})
export default class CustomFieldUploadView extends mixins(CustomFieldViewMixin) {
  formatBytes = formatBytes
  isImage = isImage
}
</script>
