<template>
  <q-field
    v-if="wrapInQfield"
    ref="input"
    dense
    borderless
    hide-bottom-space
    no-error-icon
    class="q-field__guest-form-field q-field__guest-form-field--no-bg"
    :model-value="modelValue"
    :rules="validationRules"
  >
    <template v-slot:control>
      <div class="w-full q-validation-component">
        <slot :validator="validator"></slot>
      </div>
    </template>
  </q-field>
  <slot v-else :validator="validator"></slot>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ICustomField } from '../custom-field-model'
import { ValidationRuleParser } from './ValidationRuleParser'

@Options({
  components: {},
})
export default class CustomFieldValidator extends Vue {
  @Prop()
  modelValue!: string | string[]

  @Prop()
  customField!: ICustomField

  @Prop({ default: true })
  wrapInQfield!: boolean

  get rulerParser() {
    return ValidationRuleParser(this.customField.type, this.customField.rules)
  }

  get validationRules(): Function[] {
    return this.rulerParser.getRules()
  }

  get isValid(): boolean {
    const errorMessage = this.rulerParser.validate(this.modelValue)
    if (typeof errorMessage === 'string') {
      return false
    } else {
      return true
    }
  }

  get validator() {
    return {
      rules: this.validationRules,
      customField: this.customField,
      isError: !this.isValid,
      isRequired: this.rulerParser.isRequired,
    }
  }
}
</script>
