
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import marked from 'marked'
import CustomFieldViewMixin from '../CustomFieldViewMixin.vue'

@Options({
  components: {},
  directives: { maska },
  emits: [],
})
export default class CustomFieldTextareaView extends mixins(CustomFieldViewMixin) {
  marked = marked
}
