<template>
  <div top v-if="customField">
    <label v-if="showLabel" class="text-grey-9 text-bold">{{ customField.label }}</label>
    <div class="markdown-container q-mb-md" v-if="value" v-html="marked(value)"></div>
    <div v-if="!value">-</div>
  </div>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import marked from 'marked'
import CustomFieldViewMixin from '../CustomFieldViewMixin.vue'

@Options({
  components: {},
  directives: { maska },
  emits: [],
})
export default class CustomFieldTextareaView extends mixins(CustomFieldViewMixin) {
  marked = marked
}
</script>
