import { ECustomFieldType, IValidationRule, EValidationRuleName } from './../custom-field-model'

export const ValidationRuleParser = (inputType?: ECustomFieldType, rules?: IValidationRule[]) => {
  const configRules = rules || []
  const parseRequiredRule = (rule: IValidationRule): Function => {
    return val => {
      return (!!val && val.length > 0) || rule.errorMessage || 'Field is required.'
    }
  }

  const isExistRule = (name: EValidationRuleName) => {
    return configRules.findIndex(p => p.ruleName === name) !== -1
  }

  function getRules(): Function[] {
    if (!inputType) {
      return []
    }

    const validationtRules: Function[] = []
    configRules.forEach(rule => {
      switch (rule.ruleName) {
        case EValidationRuleName.isRequired:
          validationtRules.push(parseRequiredRule(rule))
      }
    })

    return validationtRules
  }

  function validate(value: string | string[]): string {
    let errorMessage = ''
    const rules = getRules()
    for (const checkFunc of rules) {
      errorMessage = checkFunc(value)
    }

    return errorMessage
  }

  function hasRequiredRule() {
    return isExistRule(EValidationRuleName.isRequired)
  }

  return {
    isRequired: hasRequiredRule(),
    getRules,
    validate,
  }
}
