<template>
  <CustomFieldValidator :wrapInQfield="false" :customField="customField" v-model="value" v-slot="{ validator }">
    <div :class="`custom-field--${customField.name}`" style="max-width: 450px">
      <label
        class="text-grey-9 text-bold"
        v-field-hint="placeholder"
        v-field-required="validator.isRequired"
        v-if="showLabel"
      >
        {{ customField.label }}
      </label>
      <q-select
        outlined
        dense
        options-dense
        hide-bottom-space
        no-error-icon
        v-model="value"
        :options="customField.options"
        :label="customField.label"
        :rules="validator.rules"
        option-value="label"
        option-label="label"
        emit-value
        map-options
        class="q-field__guest-form-field"
      />
    </div>
  </CustomFieldValidator>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { debounce } from 'quasar'
import CustomFieldFormMixin from '../CustomFieldFormMixin.vue'
import CustomFieldValidator from '../../validation-rule/CustomFieldValidator.vue'
import { CustomFieldValue } from '@/utils/types'

@Options({
  components: { CustomFieldValidator },
  directives: { maska },
})
export default class CustomFieldDropdown extends mixins(CustomFieldFormMixin) {
  get value() {
    return this.modelValue || []
  }

  set value(value: CustomFieldValue) {
    this.$emit('update:modelValue', value)
    debounce(() => {
      this.onChange(value)
    }, 500).call(this)
  }
}
</script>
