<template>
  <template v-if="task._id">
    <CLoading v-if="!componentReady" />
    <q-card-section v-else>
      <div class="row items-center no-wrap">
        <label class="text-grey-9 text-bold q-mb-sm flex-grow">
          Comments
          <q-badge color="grey">{{ comments.length }}</q-badge>
        </label>
        <q-btn
          no-caps
          color="grey"
          class="q-mb-xs"
          padding="0 10px"
          @click="setActiveFormComment"
          label="add comment"
        />
      </div>
      <q-separator />
      <q-list class="q-mt-md q-mb-lg">
        <template v-for="comment in comments" :key="comment._id">
          <TaskCommentForm
            v-if="comment.creator"
            :user="comment.creator"
            :project="project"
            :task="task"
            :comment="comment"
          />
        </template>

        <TaskCommentForm ref="commentForm" :user="userInfo" :project="project" :task="task" :comment="null" />
      </q-list>
    </q-card-section>
  </template>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { TaskModel } from '@/components/task/task-model'
import { TaskCommentModel } from '../task-comment-model'

import logging from '@/utils/logging'
import TaskCommentMixin from '../mixins/TaskCommentMixin.vue'
import TaskCommentForm from './TaskCommentForm.vue'
import CLoading from '@/components/common/ui/CLoading.vue'
import { UserModel } from '@/components/user/user-model'

@Options({
  components: {
    CLoading,
    TaskCommentForm,
  },
  directives: { maska },
})
export default class TaskComments extends mixins(TaskCommentMixin) {
  @Prop({ default: {} })
  task!: TaskModel

  componentReady = false

  get loading() {
    return !this.componentReady
  }

  get project() {
    return this.$store.getters.project
  }

  get comments(): TaskCommentModel[] {
    return this.$store.getters.taskComments(this.taskId) || []
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo || this.$store.getters.guestInfo || {}
  }

  get taskId() {
    return this.task?._id
  }

  setActiveFormComment() {
    const commentForm = this.$refs.commentForm as TaskCommentForm
    if (commentForm) {
      commentForm.setActiveForm()
    }
  }

  beforeMount() {
    logging.debugRender(TaskComments.name)
  }

  async created() {
    logging.debugRender(TaskComments.name)
    if (this.taskId) {
      await this.getTaskComment(this.taskId)
    }

    this.componentReady = true
  }
}
</script>
