<template>
  <CustomFieldValidator :wrapInQfield="false" :customField="customField" v-model="value" v-slot="{ validator }">
    <div :class="`custom-field--${customField.name}`" v-if="customField">
      <label
        class="text-grey-9 text-bold"
        v-field-hint="placeholder"
        v-field-required="validator.isRequired"
        v-if="showLabel"
      >
        {{ customField.label }}
      </label>
      <template v-if="isShowInput">
        <MarkdownEditor
          :customClass="customField.class"
          :project="project"
          :style="customField.style"
          :rules="validator.rules"
          v-model="value"
        />
        <div class="q-mt-xs text-right q-gutter-sm" v-if="!isNew">
          <q-btn dense outline no-caps padding="0 10px" color="primary" @click="onChange" label="Save" />
          <q-btn dense outline no-caps padding="0 10px" color="grey" @click="cancelEditing(true)" label="Cancel" />
        </div>
      </template>
      <template v-else>
        <div class="markdown-container q-mb-md" v-if="value" v-html="marked(value)"></div>
        <span>
          <q-btn dense outline no-caps padding="0 10px" color="grey" @click="setEditting()">
            <q-icon name="drive_file_rename_outline" size="14px" color="grey-5" class="q-mr-xs" />
            Edit {{ customField.label }}
          </q-btn>
        </span>
      </template>
    </div>
  </CustomFieldValidator>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import marked from 'marked'
import MarkdownEditor from '../../../markdown/ui/MarkdownEditor.vue'
import CustomFieldFormMixin from '../CustomFieldFormMixin.vue'
import CustomFieldValidator from '../../validation-rule/CustomFieldValidator.vue'

@Options({
  components: { MarkdownEditor, CustomFieldValidator },
  directives: { maska },
  emits: [],
})
export default class CustomFieldTextarea extends mixins(CustomFieldFormMixin) {
  marked = marked
}
</script>
