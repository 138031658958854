
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import marked from 'marked'
import MarkdownEditor from '../../../markdown/ui/MarkdownEditor.vue'
import CustomFieldFormMixin from '../CustomFieldFormMixin.vue'
import CustomFieldValidator from '../../validation-rule/CustomFieldValidator.vue'

@Options({
  components: { MarkdownEditor, CustomFieldValidator },
  directives: { maska },
  emits: [],
})
export default class CustomFieldTextarea extends mixins(CustomFieldFormMixin) {
  marked = marked
}
