
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import CustomFieldViewMixin from '../CustomFieldViewMixin.vue'
import { ICustomFieldOption } from '../../custom-field-model'

@Options({
  components: {},
  directives: { maska },
})
export default class CustomFieldLabelViewInList extends mixins(CustomFieldViewMixin) {
  get options(): ICustomFieldOption[] {
    return this.customField?.options || []
  }

  get selectedOptions() {
    return this.options.filter((option: ICustomFieldOption) => this.value.includes(option.label))
  }
}
