import { render } from "./CustomFieldUpload.vue?vue&type=template&id=477f0ff3"
import script from "./CustomFieldUpload.vue?vue&type=script&lang=ts"
export * from "./CustomFieldUpload.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QImg from 'quasar/src/components/img/QImg.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QIcon,QFile,QList,QItem,QItemLabel,QImg});qInstall(script, 'directives', {Ripple});
