<template>
  <span class="text-grey-6 whitespace-nowrap">
    <q-icon size="xs" v-if="task?.commentCount" name="chat" />
    <span class="q-ml-xs">
      {{ task.commentCount }}
    </span>
  </span>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { TaskModel } from '../task-model'

@Options({
  components: {},
})
export default class TaskCommentCountBadge extends Vue {
  @Prop()
  task!: TaskModel
}
</script>
