
import { Vue, Options } from 'vue-class-component'

import { ProjectModel } from '@/components/project/project-model'
import { CUSTOM_FIELD_DISPLAY_IN_TABLE } from '@/constants/vars'
import { ECustomFieldType, ICustomField } from '@/components/custom-field/custom-field-model'
import CustomFieldDropdown from '@/components/custom-field/fields/dropdown/CustomFieldDropdown.vue'
import CustomFieldInput from '@/components/custom-field/fields/input/CustomFieldInput.vue'
import CustomFieldNumberInput from '@/components/custom-field/fields/number/CustomFieldNumberInput.vue'
import CustomFieldTextarea from '@/components/custom-field/fields/textarea/CustomFieldTextarea.vue'
import CustomFieldMember from '@/components/custom-field/fields/member/CustomFieldMember.vue'
import CustomFieldLabel from '@/components/custom-field/fields/label/CustomFieldLabel.vue'
import CustomFieldDatepicker from '@/components/custom-field/fields/datepicker/CustomFieldDatepicker.vue'
import CustomFieldUpload from '@/components/custom-field/fields/upload/CustomFieldUpload.vue'

import CustomFieldInputView from '../fields/input/CustomFieldInputView.vue'
import CustomFieldNumberView from '../fields/number/CustomFieldNumberView.vue'
import CustomFieldTextareaView from '../fields/textarea/CustomFieldTextareaView.vue'
import CustomFieldDropdownView from '../fields/dropdown/CustomFieldDropdownView.vue'
import CustomFieldUploadView from '../fields/upload/CustomFieldUploadView.vue'
import CustomFieldCheckBoxView from '../fields/checkbox/CustomFieldCheckBoxView.vue'
import CustomFieldCheckBox from '../fields/checkbox/CustomFieldCheckBox.vue'
import CustomFieldDatepickerView from '../fields/datepicker/CustomFieldDatepickerView.vue'
import CustomFieldLabelView from '../fields/label/CustomFieldLabelView.vue'
import CustomFieldLabelViewInList from '../fields/label/CustomFieldLabelViewInList.vue'
import CustomFieldRadio from '../fields/radio/CustomFieldRadio.vue'
import CustomFieldRadioView from '../fields/radio/CustomFieldRadioView.vue'

@Options({
  components: {},
})
export default class CustomFieldMixin extends Vue {
  customFieldComponents = {
    [ECustomFieldType.input]: CustomFieldInput,
    [ECustomFieldType.number]: CustomFieldNumberInput,
    [ECustomFieldType.textarea]: CustomFieldTextarea,
    [ECustomFieldType.dropdown]: CustomFieldDropdown,
    [ECustomFieldType.member]: CustomFieldMember,
    [ECustomFieldType.label]: CustomFieldLabel,
    [ECustomFieldType.datepicker]: CustomFieldDatepicker,
    [ECustomFieldType.upload]: CustomFieldUpload,
    [ECustomFieldType.checkbox]: CustomFieldCheckBox,
    [ECustomFieldType.radio]: CustomFieldRadio,
  }

  customFieldComponentsView = {
    [ECustomFieldType.input]: CustomFieldInputView,
    [ECustomFieldType.number]: CustomFieldNumberView,
    [ECustomFieldType.textarea]: CustomFieldTextareaView,
    [ECustomFieldType.dropdown]: CustomFieldDropdownView,
    [ECustomFieldType.member]: CustomFieldMember,
    [ECustomFieldType.label]: CustomFieldLabelView,
    [ECustomFieldType.datepicker]: CustomFieldDatepickerView,
    [ECustomFieldType.upload]: CustomFieldUploadView,
    [ECustomFieldType.checkbox]: CustomFieldCheckBoxView,
    [ECustomFieldType.radio]: CustomFieldRadioView,
  }

  customFieldComponentsViewList = {
    [ECustomFieldType.label]: CustomFieldLabelViewInList,
  }

  get workspaceId() {
    return this.$route.params.workspaceId
  }

  get project(): ProjectModel {
    return this.$store.getters.project
  }

  get customFields(): ICustomField[] {
    return this.project?.form?.fields || []
  }

  get customFieldsToDisplayInTalble() {
    return this.customFields.filter(field => {
      return field.type && CUSTOM_FIELD_DISPLAY_IN_TABLE.includes(field.type)
    })
  }
}
