
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { isImage, randomString } from '@/utils/helpers'
import { ProjectModel } from '@/components/project/project-model'
import { IUser, IUserWithPermission } from '@/components/user/user-model'

import MarkdownHelper from '@/utils/markdown'
import insertTextAtCursor from 'insert-text-at-cursor'
import marked from 'marked'
import getCaretCoordinates from 'textarea-caret'
import UploadApi from '@/components/upload/upload-api'
import UploadingProgress from '../../upload/ui/UploadingProgress.vue'
import UserAvatar from '@/components/user/ui/UserAvatar.vue'

@Options({
  components: { UploadingProgress, UserAvatar },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class MarkdownEditor extends Vue {
  @Prop({ default: '' })
  modelValue!: string

  @Prop({ default: '' })
  placeholder!: string

  @Prop({ default: false })
  autofocus!: boolean

  @Prop({ default: '' })
  customClass!: string

  @Prop({ default: {} })
  style!: Record<string, string>

  @Prop({ default: {} })
  project!: ProjectModel

  @Prop()
  rules!: Function[]

  mode = 'write'
  files: File[] = []

  isImage = isImage
  marked = marked

  searchQuery = ''
  shouldShowSugession = false
  textAreaId = randomString()
  memberListId = randomString()

  // eslint-disable-next-line
  textEditorElCached: any = null
  // eslint-disable-next-line
  memberListElCached: any = null

  get value() {
    return this.modelValue
  }

  set value(value: string) {
    this.$emit('update:modelValue', value)
  }

  get members(): IUserWithPermission[] {
    return (this.project.members || []).filter((item: IUserWithPermission) => {
      const toSearch = `${item.displayName}`.toLowerCase()
      const keyword = this.searchQuery.replace('@', '').toLowerCase()
      const match1 = !this.searchQuery || toSearch.indexOf(keyword) >= 0

      return match1
    })
  }

  get isWriteMode() {
    return this.mode === 'write'
  }

  textEditorEl() {
    if (!this.textEditorElCached) {
      this.textEditorElCached = document.getElementById(this.textAreaId)
    }

    return this.textEditorElCached
  }

  memberListEl() {
    if (!this.memberListElCached) {
      this.memberListElCached = document.getElementById(this.memberListId)
    }

    return this.memberListElCached
  }

  // eslint-disable-next-line
  getCurrentWordRange(el: any) {
    const stopCharacters = [' ', '\n', '\r', '\t']
    const text = ` ${el.value} `
    let start = el.selectionStart
    let end = el.selectionEnd
    while (start > 0) {
      if (stopCharacters.indexOf(text[start]) === -1) {
        --start
      } else {
        break
      }
    }

    ++start
    while (end < text.length) {
      if (stopCharacters.indexOf(text[end]) === -1) {
        ++end
      } else {
        break
      }
    }

    return { start, end }
  }

  // eslint-disable-next-line
  currentCaretWord(el: any) {
    const text = ` ${el.value} `
    const { start, end } = this.getCurrentWordRange(el)
    return text.substr(start, end - start)
  }

  attachInputListener() {
    const el = this.textEditorEl()
    if (!el) {
      return
    }

    el.addEventListener('input', () => {
      const caret = getCaretCoordinates(el, el.selectionEnd)
      const word = this.currentCaretWord(el)
      if (word.indexOf('@') < 0) {
        this.shouldShowSugession = false
        return
      }

      this.handleDisplayMentionDropdown(word, caret.top + caret.height, caret.left)
    })

    // eslint-disable-next-line
    el.addEventListener('keypress', (e: any) => {
      if (e.keyCode === 13 && this.shouldShowSugession && this.members.length) {
        this.onSelectMentionUser(this.members[0])
        return false
      }
    })
  }

  handleDisplayMentionDropdown(word: string, top: number, left: number) {
    this.searchQuery = word
    const memberListEl = this.memberListEl()
    memberListEl.style.top = `${top}px`
    memberListEl.style.left = `${left}px`
    this.shouldShowSugession = true
  }

  onSelectMentionUser(user: IUser) {
    this.shouldShowSugession = false
    const el = this.textEditorEl()
    const { start, end } = this.getCurrentWordRange(el)
    el.focus()
    el.setRangeText('@', start - 1, end, 'end')
    insertTextAtCursor(el, `${user.username} `)
  }

  @Watch('files')
  async handleUpload() {
    if (!this.files.length) {
      return false
    }

    const el = this.textEditorEl()
    for (const file of this.files) {
      const uploaded = await UploadApi.uploadFile(file)
      if (uploaded && uploaded.filename) {
        if (isImage(uploaded.mimetype)) {
          insertTextAtCursor(el, MarkdownHelper.makeImage(uploaded.filename, uploaded.url))
        } else {
          insertTextAtCursor(el, MarkdownHelper.makeLink(uploaded.filename, uploaded.url))
        }
      }
    }

    this.files = []
  }

  mounted() {
    this.attachInputListener()
  }
}
