<template>
  <q-item class="c-animate">
    <q-item-section avatar top>
      <UserAvatar :user="user" />
    </q-item-section>
    <q-item-section>
      <div class="row no-wrap q-gutter-sm">
        <template v-if="!isNew">
          <strong>{{ user.displayName }}</strong>
          <span class="text-grey-5 text-xs">
            {{ formatDateTime(comment.updatedAt) }}
          </span>
          <template v-if="comment.canWrite">
            <q-btn outline padding="0 5px" class="c-animate--hover-display" color="grey" @click="setActiveForm">
              <q-icon name="edit" color="grey-5" />
            </q-btn>
            <q-btn outline padding="0 5px" class="c-animate--hover-display" color="grey" @click="onDelete">
              <q-icon name="delete_outline" color="grey-5" />
            </q-btn>
          </template>
        </template>
      </div>
      <div v-if="!isActiveForm">
        <q-btn
          outline
          no-caps
          class="full-width bg-white q-mt-sm"
          align="left"
          v-if="isNew"
          @click="isActiveForm = true"
        >
          <span class="text-grey">Add a comment</span>
        </q-btn>
        <div class="markdown-container q-mt-sm" v-else v-html="marked(localComment.message)"></div>
      </div>
      <div v-if="isActiveForm">
        <MarkdownEditor :autofocus="true" :project="project" v-model="localComment.message" />
      </div>
      <div class="row no-wrap q-mt-sm" v-if="isActiveForm">
        <q-space />
        <div class="q-gutter-sm">
          <q-btn outline @click="onCancel">Cancel</q-btn>
          <q-btn :disable="!localComment.message" color="primary" @click="onSubmit"> Save </q-btn>
        </div>
      </div>
    </q-item-section>
  </q-item>
</template>
<script lang="ts">
import { Prop } from 'vue-property-decorator'
import { Options, mixins } from 'vue-class-component'
import { formatDateTime } from '@/utils/helpers'
import { ITaskComment, TaskCommentModel } from '@/components/comment/task-comment-model'
import { UserModel } from '@/components/user/user-model'
import { ProjectModel } from '@/components/project/project-model'
import { TaskModel } from '@/components/task/task-model'

import cloneDeep from 'lodash/cloneDeep'
import UserAvatar from '@/components/user/ui/UserAvatar.vue'
import marked from 'marked'
import TaskMixin from '@/components/task/mixins/TaskMixin.vue'
import TaskCommentMixin from '../mixins/TaskCommentMixin.vue'
import MarkdownEditor from '@/components/markdown/ui/MarkdownEditor.vue'

@Options({
  components: { UserAvatar, MarkdownEditor },
})
export default class TaskCommentForm extends mixins(TaskMixin, TaskCommentMixin) {
  @Prop({ default: {} })
  comment!: TaskCommentModel

  @Prop({ default: {} })
  user!: UserModel

  @Prop({ default: {} })
  project!: ProjectModel

  @Prop({ default: {} })
  task!: TaskModel

  localComment: ITaskComment = {}

  isActiveForm = false
  formatDateTime = formatDateTime
  marked = marked

  get isNew() {
    return !this.comment?._id
  }

  setActiveForm() {
    this.isActiveForm = false
    this.$nextTick(() => {
      this.isActiveForm = true
    })
  }

  onCancel() {
    this.isActiveForm = false
  }

  onSubmit() {
    this.isActiveForm = false
    const comment = cloneDeep(this.localComment)
    if (this.isNew) {
      this.localComment.message = ''
    }

    if (this.isNew) {
      return this.onAddComment(comment)
    }

    return this.onUpdateComment(comment)
  }

  onDelete() {
    this.$q
      .dialog({
        title: 'Confirm',
        message: 'Are you sure you want to delete?',
        cancel: true,
        persistent: true,
      })
      .onOk(() => {
        this.onDeleteComment(this.comment)
      })
  }

  onAddComment(comment: ITaskComment) {
    if (this.task._id) {
      return this.addComment(this.task._id, comment)
    }
  }

  onUpdateComment(comment: ITaskComment) {
    return this.updateComment(comment)
  }

  onDeleteComment(comment: ITaskComment) {
    return this.deleteComment(comment)
  }

  created() {
    this.localComment = this.comment?.sertialize() || {}
  }
}
</script>
