<template>
  <CustomFieldValidator :customField="customField" v-model="value" v-slot="{ validator }">
    <div :class="`custom-field--${customField.name}`" style="max-width: 450px">
      <label
        class="text-grey-9 text-bold"
        v-field-hint="placeholder"
        v-field-required="validator.isRequired"
        v-if="showLabel"
      >
        {{ customField.label }}
      </label>
      <q-option-group type="radio" v-model="value" :options="options" />
    </div>
  </CustomFieldValidator>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { debounce } from 'quasar'
import CustomFieldFormMixin from '../CustomFieldFormMixin.vue'
import { ICustomFieldOption } from '../../custom-field-model'
import CustomFieldValidator from '../../validation-rule/CustomFieldValidator.vue'
import { CustomFieldValue } from '@/utils/types'

@Options({
  components: { CustomFieldValidator },
  directives: { maska },
})
export default class CustomFieldRadio extends mixins(CustomFieldFormMixin) {
  get value() {
    return this.modelValue
  }

  set value(value: CustomFieldValue) {
    this.$emit('update:modelValue', value)
    debounce(() => {
      this.onChange(value)
    }, 500).call(this)
  }

  get options() {
    return (this.customField.options || []).map((option: ICustomFieldOption) => {
      return {
        label: option.label,
        value: option.label,
      }
    })
  }
}
</script>
