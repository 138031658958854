
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import debounce from 'quasar/src/utils/debounce.js';
import CustomFieldFormMixin from '../CustomFieldFormMixin.vue'
import CustomFieldValidator from '../../validation-rule/CustomFieldValidator.vue'
import { CustomFieldValue } from '@/utils/types'

@Options({
  components: { CustomFieldValidator },
  directives: { maska },
})
export default class CustomFieldDropdown extends mixins(CustomFieldFormMixin) {
  get value() {
    return this.modelValue || []
  }

  set value(value: CustomFieldValue) {
    this.$emit('update:modelValue', value)
    debounce(() => {
      this.onChange(value)
    }, 500).call(this)
  }
}
