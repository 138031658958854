<template>
  <div class="custom-fields--item">
    <label v-if="showLabel" class="text-grey-9 text-bold">{{ customField.label }}</label>
    <div>
      <q-chip v-if="value" :ripple="false" outline square color="primary" text-color="white" icon="event">
        {{ value }}
      </q-chip>
      <span v-else>-</span>
    </div>
  </div>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import CustomFieldViewMixin from '../CustomFieldViewMixin.vue'

@Options({
  components: {},
  directives: { maska },
})
export default class CustomFieldDatepickerView extends mixins(CustomFieldViewMixin) {}
</script>
