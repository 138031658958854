
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import CustomFieldFormMixin from '../CustomFieldFormMixin.vue'
import { CustomFieldValue } from '@/utils/types'
import CustomFieldValidator from '../../validation-rule/CustomFieldValidator.vue'
import NumericFieldInput from './NumericFieldInput.vue'

@Options({
  components: { CustomFieldValidator, NumericFieldInput },
  directives: { maska },
})
export default class CustomFieldInput extends mixins(CustomFieldFormMixin) {
  get value() {
    return this.modelValue || 0
  }

  set value(value: CustomFieldValue) {
    this.$emit('update:modelValue', value)
    this.onChange(value)
  }

  get numericFormat() {
    const formatStyle = this.customField.formatStyle
    return formatStyle
      ? {
          customFixSymbol: formatStyle.fixSymbol,
          customFixSymbolPosition: formatStyle.fixSymbolPosition,
          precision: formatStyle.precision,
        }
      : {}
  }
}
