
import { Options, mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IFileUploadResult } from '@/utils/types'
import { formatBytes, isImage } from '@/utils/helpers'

import CustomFieldFormMixin from '../CustomFieldFormMixin.vue'
import UploadingProgress from '../../../upload/ui/UploadingProgress.vue'
import UploadApi from '@/components/upload/upload-api'
import CustomFieldValidator from '../../validation-rule/CustomFieldValidator.vue'

@Options({
  components: { UploadingProgress, CustomFieldValidator },
  directives: { maska },
})
export default class CustomFieldUpload extends mixins(CustomFieldFormMixin) {
  formatBytes = formatBytes
  isImage = isImage

  files: File[] = []

  get value() {
    return this.modelValue as IFileUploadResult[]
  }

  set value(value: IFileUploadResult[]) {
    this.$emit('update:modelValue', value)
    this.onChange(value)
  }

  @Watch('files')
  async handleUpload() {
    if (!this.files.length) {
      return false
    }

    const uploadedValue = this.value || []
    for (const file of this.files) {
      const uploaded = await UploadApi.uploadFile(file)
      if (uploaded && uploaded.filename) {
        uploadedValue.push({ ...uploaded, size: file.size })
      }
    }
    this.value = uploadedValue
    this.onChange(this.value.toString())
    this.files = []
  }

  removeFile(file: IFileUploadResult) {
    const index = this.value.indexOf(file)
    if (index >= 0) {
      this.value.splice(index, 1)
    }

    this.onChange(this.value.toString())
  }
}
