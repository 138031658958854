
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import CustomFieldFormMixin from '../CustomFieldFormMixin.vue'
import CustomFieldValidator from '../../validation-rule/CustomFieldValidator.vue'
import { CustomFieldValue } from '@/utils/types'

@Options({
  components: { CustomFieldValidator },
  directives: { maska },
})
export default class CustomFieldDatepicker extends mixins(CustomFieldFormMixin) {
  get value() {
    return this.modelValue ?? (this.customField.defaultValue as string)
  }

  set value(value: CustomFieldValue) {
    this.$emit('update:modelValue', value)
    this.onChange(value)
  }
}
