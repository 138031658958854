
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { APP_USER_STORE_KEY } from '@/constants/vars'

interface ITaskFilters {
  searchQuery: string
  filterState: string
  filterStateParent: string
  creatorIds: string[]
  hiddenColumns: string[]
}

@Options({
  components: {},
})
export default class TaskDisplayFilterMixin extends Vue {
  defaultFiltersTask = {
    filterState: '',
    filterStateParent: '',
    searchQuery: '',
    creatorIds: [],
    hiddenColumns: [],
  }

  filteredTasks: ITaskFilters = this.defaultFiltersTask

  @Watch('filteredTasks', { deep: true })
  filteredTasksChanged(newVal: ITaskFilters) {
    if (newVal) {
      localStorage.setItem(this.storeFilterKey, JSON.stringify(newVal))
    }
  }

  get workspaceId() {
    return this.$route.params.workspaceId
  }

  get storeFilterKey() {
    return `${APP_USER_STORE_KEY.TASK_FILTERS}-${this.workspaceId}`
  }

  setInitTaskFilters() {
    const stored = localStorage.getItem(this.storeFilterKey)
    this.filteredTasks = stored ? JSON.parse(stored) : this.defaultFiltersTask
  }

  created() {
    this.setInitTaskFilters()
  }
}
