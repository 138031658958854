import { render } from "./CustomFieldUploadView.vue?vue&type=template&id=6cc24d26"
import script from "./CustomFieldUploadView.vue?vue&type=script&lang=ts"
export * from "./CustomFieldUploadView.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QImg from 'quasar/src/components/img/QImg.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QItemLabel,QImg});qInstall(script, 'directives', {Ripple});
