
import { mixins, Options } from 'vue-class-component'
import { CustomFieldValue } from '@/utils/types'
import CustomFieldFormMixin from '../CustomFieldFormMixin.vue'
import CustomFieldValidator from '../../validation-rule/CustomFieldValidator.vue'

@Options({
  components: { CustomFieldValidator },
})
export default class CustomFieldInput extends mixins(CustomFieldFormMixin) {
  get value() {
    return this.modelValue
  }

  set value(value: CustomFieldValue) {
    this.$emit('update:modelValue', value)
    this.onChange(value)
  }
}
