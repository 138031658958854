
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { TaskModel } from '../task-model'

@Options({
  components: {},
})
export default class TaskCommentCountBadge extends Vue {
  @Prop()
  task!: TaskModel
}
