import { render } from "./CustomFieldDropdown.vue?vue&type=template&id=db094baa"
import script from "./CustomFieldDropdown.vue?vue&type=script&lang=ts"
export * from "./CustomFieldDropdown.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QSelect from 'quasar/src/components/select/QSelect.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QField});
