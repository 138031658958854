import { render } from "./MarkdownEditor.vue?vue&type=template&id=485911a2"
import script from "./MarkdownEditor.vue?vue&type=script&lang=ts"
export * from "./MarkdownEditor.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QField from 'quasar/src/components/field/QField.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtnToggle,QSpace,QBtn,QIcon,QFile,QInput,QField,QList,QItem,QItemSection});qInstall(script, 'directives', {Ripple});
