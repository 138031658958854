import { render } from "./TaskComments.vue?vue&type=template&id=bfde7356"
import script from "./TaskComments.vue?vue&type=script&lang=ts"
export * from "./TaskComments.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QList from 'quasar/src/components/item/QList.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCardSection,QBadge,QBtn,QSeparator,QList});
