import { render } from "./CustomFieldDatepicker.vue?vue&type=template&id=62dc8327"
import script from "./CustomFieldDatepicker.vue?vue&type=script&lang=ts"
export * from "./CustomFieldDatepicker.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QField from 'quasar/src/components/field/QField.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QField,QIcon,QPopupProxy,QDate});
