<template>
  <q-list bordered dense separator class="q-list--no-padding">
    <q-item clickable v-ripple>
      <div>
        <q-btn
          flat
          padding="xs"
          class="full-width"
          align="left"
          :label="customField.label"
          icon="group"
          color="white"
          text-color="black"
          size="sm"
          auto-close
          menu-anchor="top left"
          menu-self="top left"
        >
          <q-menu style="min-width: 200px">
            <q-list separator dense class="q-list--no-padding">
              <q-item
                clickable
                v-ripple
                v-for="user in members"
                :key="user._id"
                @click="toggleSelect(user)"
                :active="isActive(user)"
              >
                <div avatar>
                  <UserAvatar :user="user" :size="25" />
                </div>
                <div>{{ user.displayName }}</div>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
    </q-item>
    <q-item clickable v-ripple v-for="user in selectedUsers" :key="user._id">
      <div avatar>
        <UserAvatar :user="user" :size="30" />
      </div>
      <div>{{ user.displayName }}</div>
      <div side v-if="isActive(user)">
        <q-btn flat icon="close" round size="xs" @click="toggleSelect(user)" />
      </div>
    </q-item>
  </q-list>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { debounce } from 'quasar'
import { WorkspaceModel } from '@/components/workspace/workspace-model'
import { IUser, IUserWithPermission, UserModel } from '@/components/user/user-model'

import uniqBy from 'lodash/uniqBy'
import CustomFieldFormMixin from '../CustomFieldFormMixin.vue'
import UserAvatar from '@/components/user/ui/UserAvatar.vue'
import { CustomFieldValue } from '@/utils/types'

@Options({
  components: { UserAvatar },
  directives: { maska },
})
export default class CustomFieldMember extends mixins(CustomFieldFormMixin) {
  get value() {
    return this.modelValue || []
  }

  set value(value: CustomFieldValue) {
    this.$emit('update:modelValue', value)
    debounce(() => {
      this.onChange(value)
    }, 500).call(this)
  }

  get workspace(): WorkspaceModel {
    return this.$store.getters.workspace || {}
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo || {}
  }

  get projectMembers(): IUserWithPermission[] {
    return this.$store.getters.project?.members || []
  }

  get members(): IUserWithPermission[] {
    const workspaceMembers = this.workspace.members || []
    const members = workspaceMembers.concat(this.projectMembers)
    return uniqBy(members, '_id')
  }

  get selectedUsers() {
    const users: IUser[] = []
    const userIds = this.modelValue as string[]
    for (const userId of userIds) {
      const user = this.members.find(u => u._id === userId)
      if (user) {
        users.push(user)
      }
    }

    return users
  }

  isActive(user: IUser) {
    return (this.value as string[]).includes(user._id || '')
  }

  toggleSelect(user: IUser) {
    const value = this.value as string[]
    if (!value?.length) {
      this.value = []
    }

    const index = value.indexOf(user._id as string)

    if (index >= 0) {
      value.splice(index, 1)
    } else {
      value.push(user._id as string)
    }

    this.value = value
  }
}
</script>
