<template>
  <CustomFieldValidator :customField="customField" v-model="value" v-slot="{ validator }">
    <div v-if="customField" :class="`q-mt-sm custom-field--${customField.name} ${customField.class}`">
      <div>
        <label
          class="text-grey-9 text-bold block"
          v-field-hint="placeholder"
          v-field-required="validator.isRequired"
          v-if="showLabel"
        >
          {{ customField.label }}
        </label>
        <q-btn no-caps outline class="position-relative overflow-hidden bg-white" padding="0 10px">
          <q-icon name="attachment" color="grey-5" class="q-mr-xs" size="xs" />
          Attach files...
          <q-file multiple dense flat v-model="files" class="absolute absolute-center opacity-0"> </q-file>
        </q-btn>
      </div>

      <!-- Uploaded files -->
      <q-list separator dense class="q-mt-sm q-list--no-padding">
        <q-item clickable v-ripple v-for="file in value" :key="file.url">
          <div>
            <q-item-label class="full-width ellipsis text-blue text-subtitle2 text-bold">
              <a :href="file.url" target="_blank" class="text-blue">
                {{ file.filename }}
                <span class="text-grey">
                  {{ formatBytes(file.size) }}
                </span>
              </a>
            </q-item-label>
            <template v-if="isImage(file.mimetype)">
              <a :href="file.url" target="_blank" class="q-mt-sm w-full">
                <q-img class="q-img__attachments" :src="file.url" :href="file.url" target="_blank" />
              </a>
            </template>
          </div>

          <div top side>
            <q-btn class="gt-xs" size="12px" flat dense round icon="close" @click="removeFile(file)" />
          </div>
        </q-item>
      </q-list>
    </div>
    <UploadingProgress :files="files" />
  </CustomFieldValidator>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IFileUploadResult } from '@/utils/types'
import { formatBytes, isImage } from '@/utils/helpers'

import CustomFieldFormMixin from '../CustomFieldFormMixin.vue'
import UploadingProgress from '../../../upload/ui/UploadingProgress.vue'
import UploadApi from '@/components/upload/upload-api'
import CustomFieldValidator from '../../validation-rule/CustomFieldValidator.vue'

@Options({
  components: { UploadingProgress, CustomFieldValidator },
  directives: { maska },
})
export default class CustomFieldUpload extends mixins(CustomFieldFormMixin) {
  formatBytes = formatBytes
  isImage = isImage

  files: File[] = []

  get value() {
    return this.modelValue as IFileUploadResult[]
  }

  set value(value: IFileUploadResult[]) {
    this.$emit('update:modelValue', value)
    this.onChange(value)
  }

  @Watch('files')
  async handleUpload() {
    if (!this.files.length) {
      return false
    }

    const uploadedValue = this.value || []
    for (const file of this.files) {
      const uploaded = await UploadApi.uploadFile(file)
      if (uploaded && uploaded.filename) {
        uploadedValue.push({ ...uploaded, size: file.size })
      }
    }
    this.value = uploadedValue
    this.onChange(this.value.toString())
    this.files = []
  }

  removeFile(file: IFileUploadResult) {
    const index = this.value.indexOf(file)
    if (index >= 0) {
      this.value.splice(index, 1)
    }

    this.onChange(this.value.toString())
  }
}
</script>
