<template>
  <div class="custom-fields--item">
    <label v-if="showLabel" class="text-grey-9 text-bold">{{ customField.label }}</label>
    <div>
      <q-chip
        square
        :ripple="false"
        v-for="option in selectedOptions"
        :key="option.value"
        @remove="toggleSelect(option.label)"
        :style="{ background: option.color, color: '#fff' }"
      >
        {{ option.label }}
      </q-chip>
    </div>
    <div v-if="!value?.length">-</div>
  </div>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import CustomFieldViewMixin from '../CustomFieldViewMixin.vue'
import { ICustomFieldOption } from '../../custom-field-model'

@Options({
  components: {},
  directives: { maska },
})
export default class CustomFieldLabelView extends mixins(CustomFieldViewMixin) {
  get options(): ICustomFieldOption[] {
    return this.customField?.options || []
  }

  get selectedOptions() {
    return this.options.filter((option: ICustomFieldOption) => this.value.includes(option.label))
  }
}
</script>
