class MarkdownHelper {
  makeImage(filename: string, url: string) {
    return `![${filename}](${url})\n`
  }

  makeLink(anchorText: string, url: string) {
    return `[${anchorText}](${url})\n`
  }
}

export default new MarkdownHelper()
