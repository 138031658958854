
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import debounce from 'quasar/src/utils/debounce.js';
import { WorkspaceModel } from '@/components/workspace/workspace-model'
import { IUser, IUserWithPermission, UserModel } from '@/components/user/user-model'

import uniqBy from 'lodash/uniqBy'
import CustomFieldFormMixin from '../CustomFieldFormMixin.vue'
import UserAvatar from '@/components/user/ui/UserAvatar.vue'
import { CustomFieldValue } from '@/utils/types'

@Options({
  components: { UserAvatar },
  directives: { maska },
})
export default class CustomFieldMember extends mixins(CustomFieldFormMixin) {
  get value() {
    return this.modelValue || []
  }

  set value(value: CustomFieldValue) {
    this.$emit('update:modelValue', value)
    debounce(() => {
      this.onChange(value)
    }, 500).call(this)
  }

  get workspace(): WorkspaceModel {
    return this.$store.getters.workspace || {}
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo || {}
  }

  get projectMembers(): IUserWithPermission[] {
    return this.$store.getters.project?.members || []
  }

  get members(): IUserWithPermission[] {
    const workspaceMembers = this.workspace.members || []
    const members = workspaceMembers.concat(this.projectMembers)
    return uniqBy(members, '_id')
  }

  get selectedUsers() {
    const users: IUser[] = []
    const userIds = this.modelValue as string[]
    for (const userId of userIds) {
      const user = this.members.find(u => u._id === userId)
      if (user) {
        users.push(user)
      }
    }

    return users
  }

  isActive(user: IUser) {
    return (this.value as string[]).includes(user._id || '')
  }

  toggleSelect(user: IUser) {
    const value = this.value as string[]
    if (!value?.length) {
      this.value = []
    }

    const index = value.indexOf(user._id as string)

    if (index >= 0) {
      value.splice(index, 1)
    } else {
      value.push(user._id as string)
    }

    this.value = value
  }
}
