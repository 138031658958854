
import { Vue, Options } from 'vue-class-component'
import { ITask, TaskModel } from '@/components/task/task-model'
import { UserModel } from '@/components/user/user-model'
import { ICustomField, ICustomFieldChange } from '@/components/custom-field/custom-field-model'
import { ACTION_TASK } from '../task-actions'
import debounce from 'quasar/src/utils/debounce.js';

@Options({
  components: {},
})
export default class TaskMixin extends Vue {
  get userInfo(): UserModel {
    return this.$store.getters.userInfo || {}
  }

  async getProjectTasks(projectId: string) {
    this.$store.dispatch(ACTION_TASK.LOAD_ITEMS, { projectId })
  }

  async getGuestTasks() {
    this.$store.dispatch(ACTION_TASK.LOAD_ITEMS_OF_GUEST)
  }

  async addTask(projectId: string, task: ITask) {
    return this.$store.dispatch(ACTION_TASK.ADD, {
      projectId,
      input: task,
    })
  }

  async updateTask(task: ITask) {
    return this.$store.dispatch(ACTION_TASK.UPDATE, { task })
  }

  async updateTaskSeen(task: TaskModel) {
    return debounce(() => {
      task.updateSeen()
    }, 2000).call(this)
  }

  async removeTask(task: ITask) {
    return this.$store.dispatch(ACTION_TASK.DELETE, { id: task._id })
  }

  isStaticTaskField(field: ICustomField) {
    return ['title'].includes(field.name || '')
  }

  doUpdateTaskField(task: ITask, payload: ICustomFieldChange) {
    if (!payload.field.name) {
      return false
    }

    // We have task fields by default and custom fields
    // Do for default task field
    if (this.isStaticTaskField(payload.field)) {
      if (!payload.field.name) {
        return false
      }
      // [TaskMixin]
      this.updateTask({ _id: task._id, [payload.field.name]: payload.value })
    } else {
      // Custom fields update
      if (!payload.field.name) {
        return false
      }

      this.updateTask({ _id: task._id, customFieldsVal: task.customFieldsVal })
    }
  }
}
