
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { ICustomField } from '@/components/custom-field/custom-field-model'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue', 'update:change'],
})
export default class CustomFieldViewMixin extends Vue {
  @Prop({ default: '' })
  value!: string

  @Prop({ default: true })
  showLabel!: boolean

  @Prop()
  customField!: ICustomField
}
