
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { formatBytes, isImage } from '@/utils/helpers'
import CustomFieldViewMixin from '../CustomFieldViewMixin.vue'

@Options({
  components: {},
  directives: { maska },
})
export default class CustomFieldUploadView extends mixins(CustomFieldViewMixin) {
  formatBytes = formatBytes
  isImage = isImage
}
