import { render } from "./TaskCommentForm.vue?vue&type=template&id=16c6ed6a"
import script from "./TaskCommentForm.vue?vue&type=script&lang=ts"
export * from "./TaskCommentForm.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QItem,QItemSection,QBtn,QIcon,QSpace});
