<template>
  <q-btn outline no-caps padding="0 10px" align="left">
    <q-icon name="more" color="grey-5" />
    <span class="q-ml-sm">{{ customField.label }}</span>

    <q-menu style="min-width: 200px" auto-close>
      <q-list separator dense class="q-list--no-padding">
        <q-item
          clickable
          v-ripple
          v-for="option in options"
          :key="option.value"
          @click="toggleSelect(option)"
          :active="isActive(option.label)"
        >
          <div>
            <q-chip dense :style="{ background: option.color, color: '#fff' }">{{ option.label }}</q-chip>
          </div>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
  <div class="q-mt-sm q-gutter-xs">
    <q-chip
      removable
      v-for="option in selectedOptions"
      :key="option.value"
      @remove="toggleSelect(option.label)"
      :style="{ background: option.color, color: '#fff' }"
    >
      {{ option.label }}
    </q-chip>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { debounce } from 'quasar'
import { ICustomFieldOption } from '../../custom-field-model'
import CustomFieldFormMixin from '../CustomFieldFormMixin.vue'
import { CustomFieldValue } from '@/utils/types'

@Options({
  components: {},
  directives: { maska },
})
export default class CustomFieldLabel extends mixins(CustomFieldFormMixin) {
  get value() {
    return this.modelValue || []
  }

  set value(value: CustomFieldValue) {
    this.$emit('update:modelValue', value)
    debounce(() => {
      this.onChange(value)
    }, 500).call(this)
  }

  get options(): ICustomFieldOption[] {
    return this.customField?.options || []
  }

  get selectedOptions() {
    return this.options.filter((option: ICustomFieldOption) => (this.value as string[]).includes(option.label))
  }

  isActive(selectedValue: string) {
    return (this.value as string[]).includes(selectedValue)
  }

  handleMultiSelectToggle(option: ICustomFieldOption) {
    let value = this.value as string[]
    if (!value?.length) {
      value = []
    }

    const index = value.indexOf(option.label)
    if (index >= 0) {
      value.splice(index, 1)
    } else {
      value.push(option.label)
    }

    this.value = value
  }

  handleSingleSelectToggle(option: ICustomFieldOption) {
    let value = this.value as string[]
    if (!value?.length) {
      this.value = []
    }

    const index = value.indexOf(option.label)
    if (index >= 0) {
      value = []
    } else {
      value = [option.label]
    }

    this.value = value
  }

  toggleSelect(option: ICustomFieldOption) {
    if (this.customField?.multiSelect) {
      this.handleMultiSelectToggle(option)
    } else {
      this.handleSingleSelectToggle(option)
    }
  }
}
</script>
