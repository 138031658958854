<template>
  <CustomFieldValidator :wrapInQfield="false" :customField="customField" v-model="value" v-slot="{ validator }">
    <div :class="`custom-field--${customField.name}`" v-if="customField">
      <label
        class="text-grey-9 text-bold"
        v-field-hint="placeholder"
        v-field-required="validator.isRequired"
        v-if="showLabel"
      >
        {{ customField.label }}
      </label>
      <q-input
        :rounded="false"
        outlined
        dense
        hide-bottom-space
        no-error-icon
        v-model="value"
        mask="date"
        class="q-field__guest-form-field"
        style="max-width: 150px"
        :label="showLabel ? customField.label : ''"
        :class="customField.class"
        :placeholder="customField.placeholder"
        :rules="validator.rules"
      >
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
              <q-date minimal v-model="value"> </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
    </div>
  </CustomFieldValidator>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import CustomFieldFormMixin from '../CustomFieldFormMixin.vue'
import CustomFieldValidator from '../../validation-rule/CustomFieldValidator.vue'
import { CustomFieldValue } from '@/utils/types'

@Options({
  components: { CustomFieldValidator },
  directives: { maska },
})
export default class CustomFieldDatepicker extends mixins(CustomFieldFormMixin) {
  get value() {
    return this.modelValue ?? (this.customField.defaultValue as string)
  }

  set value(value: CustomFieldValue) {
    this.$emit('update:modelValue', value)
    this.onChange(value)
  }
}
</script>
