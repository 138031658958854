<template>
  <div v-if="customField">
    <label v-if="showLabel" class="text-grey-9 text-bold">{{ customField.label }}</label>
    <div :class="customField.class">{{ $filters.formatFieldValue(value, customField.formatStyle) }}</div>
  </div>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import CustomFieldViewMixin from '../CustomFieldViewMixin.vue'

@Options({
  components: {},
  directives: { maska },
})
export default class CustomFieldInputView extends mixins(CustomFieldViewMixin) {}
</script>
