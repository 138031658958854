<template>
  <div class="custom-fields--item">
    <label v-if="showLabel" class="text-grey-9 text-bold">{{ customField.label }}</label>
    <div class="q-mt-xs">
      <q-chip
        square
        :ripple="false"
        v-for="(val, index) in value"
        :key="index"
        dense
        color="deep-orange"
        text-color="white"
        icon="check"
      >
        {{ val }}
      </q-chip>
    </div>
    <div v-if="!value?.length">-</div>
  </div>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import CustomFieldViewMixin from '../CustomFieldViewMixin.vue'

@Options({
  components: {},
  directives: { maska },
})
export default class CustomFieldCheckBoxView extends mixins(CustomFieldViewMixin) {}
</script>
