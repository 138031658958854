<template>
  <q-input ref="inputRef" type="text" v-model="formattedValue" :rules="validationRules" />
</template>

<script>
import { useCurrencyInput } from '@sangopkg/vue-currency-input'

export default {
  name: 'NumericFieldInput',
  props: {
    modelValue: Number,
    options: Object,
    rules: Object,
  },
  setup(props) {
    Object.assign(props.options, {
      useCustomFixSymbol: true,
      hideGroupingSeparatorOnFocus: false,
    })

    const validationRules = props.rules || []

    const { formattedValue, inputRef } = useCurrencyInput(props.options)
    return { inputRef, formattedValue, validationRules }
  },
}
</script>
