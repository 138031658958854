<template>
  <div class="row no-wrap items-center">
    <q-btn-toggle
      v-model="mode"
      no-caps
      outline
      padding="0 10px"
      toggle-color="is-active"
      :options="[
        { label: 'Write', value: 'write' },
        { label: 'Preview', value: 'preview' },
      ]"
    />
    <q-space />
    <div class="q-gutter-sm">
      <q-btn outline padding="4px" class="relative-position overflow-hidden">
        <q-icon name="attachment" color="grey" />
        <q-file multiple v-model="files" class="absolute absolute-center opacity-0"> </q-file>
      </q-btn>
    </div>
  </div>
  <div class="q-mt-xs relative-position">
    <q-input
      v-show="isWriteMode"
      :for="textAreaId"
      type="textarea"
      autogrow
      clearable
      outlined
      dense
      hide-bottom-space
      no-error-icon
      v-model="value"
      :placeholder="placeholder"
      :autofocus="autofocus"
      :class="customClass"
      :input-style="{ 'min-height': '4em', 'max-height': '16em', ...style }"
      class="q-field__guest-form-field"
      :rules="rules"
    />
    <template v-if="!isWriteMode">
      <div class="markdown-container bg-white q-pa-sm border rounded-borders" v-if="value" v-html="marked(value)"></div>
      <div class="q-py-md text-grey text-subtitle2 text-center" v-if="!value">Nothing to preview</div>
    </template>

    <q-list
      v-show="shouldShowSugession && members.length"
      bordered
      separator
      dense
      class="q-list--no-padding bg-white absolute z-max"
      style="width: 200px"
      :id="memberListId"
    >
      <q-item
        clickable
        v-ripple
        v-for="(user, index) in members"
        :key="user._id"
        @click="onSelectMentionUser(user)"
        :active="index === 0"
      >
        <q-item-section avatar top>
          <UserAvatar :user="user" :size="25" />
        </q-item-section>
        <q-item-section>
          <div class="text-gray text-bold">@{{ user.username }}</div>
          <span>
            {{ user.displayName }}
          </span>
        </q-item-section>
      </q-item>
    </q-list>
  </div>

  <UploadingProgress :files="files" />
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { isImage, randomString } from '@/utils/helpers'
import { ProjectModel } from '@/components/project/project-model'
import { IUser, IUserWithPermission } from '@/components/user/user-model'

import MarkdownHelper from '@/utils/markdown'
import insertTextAtCursor from 'insert-text-at-cursor'
import marked from 'marked'
import getCaretCoordinates from 'textarea-caret'
import UploadApi from '@/components/upload/upload-api'
import UploadingProgress from '../../upload/ui/UploadingProgress.vue'
import UserAvatar from '@/components/user/ui/UserAvatar.vue'

@Options({
  components: { UploadingProgress, UserAvatar },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class MarkdownEditor extends Vue {
  @Prop({ default: '' })
  modelValue!: string

  @Prop({ default: '' })
  placeholder!: string

  @Prop({ default: false })
  autofocus!: boolean

  @Prop({ default: '' })
  customClass!: string

  @Prop({ default: {} })
  style!: Record<string, string>

  @Prop({ default: {} })
  project!: ProjectModel

  @Prop()
  rules!: Function[]

  mode = 'write'
  files: File[] = []

  isImage = isImage
  marked = marked

  searchQuery = ''
  shouldShowSugession = false
  textAreaId = randomString()
  memberListId = randomString()

  // eslint-disable-next-line
  textEditorElCached: any = null
  // eslint-disable-next-line
  memberListElCached: any = null

  get value() {
    return this.modelValue
  }

  set value(value: string) {
    this.$emit('update:modelValue', value)
  }

  get members(): IUserWithPermission[] {
    return (this.project.members || []).filter((item: IUserWithPermission) => {
      const toSearch = `${item.displayName}`.toLowerCase()
      const keyword = this.searchQuery.replace('@', '').toLowerCase()
      const match1 = !this.searchQuery || toSearch.indexOf(keyword) >= 0

      return match1
    })
  }

  get isWriteMode() {
    return this.mode === 'write'
  }

  textEditorEl() {
    if (!this.textEditorElCached) {
      this.textEditorElCached = document.getElementById(this.textAreaId)
    }

    return this.textEditorElCached
  }

  memberListEl() {
    if (!this.memberListElCached) {
      this.memberListElCached = document.getElementById(this.memberListId)
    }

    return this.memberListElCached
  }

  // eslint-disable-next-line
  getCurrentWordRange(el: any) {
    const stopCharacters = [' ', '\n', '\r', '\t']
    const text = ` ${el.value} `
    let start = el.selectionStart
    let end = el.selectionEnd
    while (start > 0) {
      if (stopCharacters.indexOf(text[start]) === -1) {
        --start
      } else {
        break
      }
    }

    ++start
    while (end < text.length) {
      if (stopCharacters.indexOf(text[end]) === -1) {
        ++end
      } else {
        break
      }
    }

    return { start, end }
  }

  // eslint-disable-next-line
  currentCaretWord(el: any) {
    const text = ` ${el.value} `
    const { start, end } = this.getCurrentWordRange(el)
    return text.substr(start, end - start)
  }

  attachInputListener() {
    const el = this.textEditorEl()
    if (!el) {
      return
    }

    el.addEventListener('input', () => {
      const caret = getCaretCoordinates(el, el.selectionEnd)
      const word = this.currentCaretWord(el)
      if (word.indexOf('@') < 0) {
        this.shouldShowSugession = false
        return
      }

      this.handleDisplayMentionDropdown(word, caret.top + caret.height, caret.left)
    })

    // eslint-disable-next-line
    el.addEventListener('keypress', (e: any) => {
      if (e.keyCode === 13 && this.shouldShowSugession && this.members.length) {
        this.onSelectMentionUser(this.members[0])
        return false
      }
    })
  }

  handleDisplayMentionDropdown(word: string, top: number, left: number) {
    this.searchQuery = word
    const memberListEl = this.memberListEl()
    memberListEl.style.top = `${top}px`
    memberListEl.style.left = `${left}px`
    this.shouldShowSugession = true
  }

  onSelectMentionUser(user: IUser) {
    this.shouldShowSugession = false
    const el = this.textEditorEl()
    const { start, end } = this.getCurrentWordRange(el)
    el.focus()
    el.setRangeText('@', start - 1, end, 'end')
    insertTextAtCursor(el, `${user.username} `)
  }

  @Watch('files')
  async handleUpload() {
    if (!this.files.length) {
      return false
    }

    const el = this.textEditorEl()
    for (const file of this.files) {
      const uploaded = await UploadApi.uploadFile(file)
      if (uploaded && uploaded.filename) {
        if (isImage(uploaded.mimetype)) {
          insertTextAtCursor(el, MarkdownHelper.makeImage(uploaded.filename, uploaded.url))
        } else {
          insertTextAtCursor(el, MarkdownHelper.makeLink(uploaded.filename, uploaded.url))
        }
      }
    }

    this.files = []
  }

  mounted() {
    this.attachInputListener()
  }
}
</script>
