<template>
  <div :class="`custom-field--${customField.name}`" v-if="customField">
    <CustomFieldValidator :wrapInQfield="false" :customField="customField" v-model="value" v-slot="{ validator }">
      <label
        v-field-hint="placeholder"
        v-field-required="validator.isRequired"
        class="text-grey-9 text-bold"
        v-if="showLabel"
      >
        {{ customField.label }}
      </label>

      <!-- Text field -->
      <div class="row no-wrap q-gutter-sm items-center">
        <slot name="prefix"></slot>
        <div class="flex-grow" v-if="isShowInput">
          <NumericFieldInput
            dense
            outlined
            v-model="value"
            hide-bottom-space
            no-error-icon
            class="q-field__guest-form-field"
            :class="customField.class"
            :placeholder="customField.placeholder"
            :rules="validator.rules"
            :options="numericFormat"
            @change="onChange"
            @blur="isEditting = false"
          />
        </div>
        <div class="flex-grow" v-else>
          <div class="hover-dashed-border" :class="customField.class" @click="setEditting">
            {{ value }}
          </div>
        </div>
        <slot name="suffix"></slot>
      </div>
    </CustomFieldValidator>
  </div>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import CustomFieldFormMixin from '../CustomFieldFormMixin.vue'
import { CustomFieldValue } from '@/utils/types'
import CustomFieldValidator from '../../validation-rule/CustomFieldValidator.vue'
import NumericFieldInput from './NumericFieldInput.vue'

@Options({
  components: { CustomFieldValidator, NumericFieldInput },
  directives: { maska },
})
export default class CustomFieldInput extends mixins(CustomFieldFormMixin) {
  get value() {
    return this.modelValue || 0
  }

  set value(value: CustomFieldValue) {
    this.$emit('update:modelValue', value)
    this.onChange(value)
  }

  get numericFormat() {
    const formatStyle = this.customField.formatStyle
    return formatStyle
      ? {
          customFixSymbol: formatStyle.fixSymbol,
          customFixSymbolPosition: formatStyle.fixSymbolPosition,
          precision: formatStyle.precision,
        }
      : {}
  }
}
</script>
