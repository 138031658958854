
import { Vue, Options } from 'vue-class-component'
import { ITaskComment } from '@/components/comment/task-comment-model'
import { ACTION_TASK_COMMENT } from '../task-comment-actions'

@Options({
  components: {},
})
export default class TaskCommentMixin extends Vue {
  async getTaskComment(taskId: string) {
    this.$store.dispatch(ACTION_TASK_COMMENT.LOAD_ITEMS, { taskId })
  }

  async addComment(taskId: string, comment: ITaskComment) {
    return this.$store.dispatch(ACTION_TASK_COMMENT.ADD, {
      taskId,
      input: comment,
    })
  }

  async updateComment(comment: ITaskComment) {
    return this.$store.dispatch(ACTION_TASK_COMMENT.UPDATE, {
      comment,
    })
  }

  async deleteComment(comment: ITaskComment) {
    return this.$store.dispatch(ACTION_TASK_COMMENT.DELETE, {
      id: comment._id,
    })
  }
}
